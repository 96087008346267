<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-for="`input-${id}`"
    :label-cols="labelCols"
    :description="description"
  >
    <b-form-input
      ref="input"
      :id="`input-${id}`"
      :name="`input-${id}`"
      type="search"
      :list="`datalist-${id}`"
      autocomplete="off"
      :state="state"
      v-model="filtre"
      @change="onChange"
      @blur="onBlur"
    ></b-form-input>
    <datalist :id="`datalist-${id}`">
      <option
        v-for="option in computedOptions"
        :key="option.codeProduit"
        :value="`${option.libelle} <${option.codeProduit}>`"
      ></option>
    </datalist>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import UtilsService from "../../../services/utils.service";
export default {
  name: "SelectProduitApproComponent",
  props: {
    // valeur du select (code produit)
    value: [String],
    // société pour filtrer les produits blacklistés (code société)
    societe: [String],
    // produits à filtrer dans la liste (liste de codes produit)
    exclude: { type: Array, default: () => [] },
    // taille max de la liste d'options
    options: { type: Number, default: 10 },
    // propriété label du form-group
    label: { type: String, default: "Produit" },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété description du form-group
    description: [String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // true pour rendre le contrôle obligatoire (false par défaut)
    required: [Boolean, String],
  },
  watch: {
    value: "valueChanged",
  },
  data() {
    return {
      id: `parcelle-${uuidv4()}`,
      filtre: "",
      state: null,
    };
  },
  computed: {
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() ?? "";
    },
    computedOptions() {
      return (
        this.$store.state.ref.produitsAppro
          // Filtrer les produits désactivés
          .filter((p) => !!p.actif)
          // Filtrer les produits blacklistés par la société
          .filter((p) => !p.blacklistSocietes.includes(this.societe))
          // Filtrer les produits de la liste d'exclusion
          .filter((p) => !this.exclude.includes(p.codeProduit))
          // Filtrer les produits qui ne correspondent pas à la recherche
          .filter((p) => p.libelle.toLowerCase().includes(this.computedFiltre))
          // Trier par libellé produit
          .sort(UtilsService.sortByStringProperty("libelle"))
          // Ne garder que les X premières options
          .slice(0, this.options)
      );
    },
    selectedObject() {
      return this.$store.getters["ref/produitAppro"](this.value);
    },
  },
  mounted() {
    this.filtre = this.selectedObject?.libelle;
  },
  methods: {
    select(item) {
      this.filtre = item?.libelle ?? "";
      this.$emit("input", item?.codeProduit ?? null);
    },
    onChange() {
      let indexDebut = this.filtre.indexOf("<");
      let indexFin = this.filtre.indexOf(">");
      if (indexDebut > 0 && indexFin > indexDebut) {
        this.select(
          this.$store.getters["ref/produitAppro"](
            this.filtre.substring(indexDebut + 1, indexFin)
          )
        );
      } else {
        this.$emit("input", null);
      }
    },
    valueChanged() {
      this.filtre = this.selectedObject?.libelle;
      this.state =
        (this.required !== true && this.required !== "true") ||
        this.selectedObject != null;
    },
    async onBlur() {
      await this.$nextTick();
      if (!this.selectedObject) {
        this.filtre = "";
      }
    },
    validate() {
      this.valueChanged();
      if (!this.state) {
        this.focus();
        throw new Error("Invalid field");
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
