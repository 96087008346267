<template>
  <section>
    <CustomInputNumberComponent
      ref="input"
      :label="label"
      :labelCols="labelCols"
      :step="1"
      :disabled="inputDisabled"
      :value="inputValue"
      @input="onInput"
      @change="onChange"
    >
      <template #append>
        <b-input-group-text @click="changeUnit">
          {{ unitValue }}
        </b-input-group-text>
      </template>
    </CustomInputNumberComponent>
  </section>
</template>

<script>
import CustomInputNumberComponent from "./CustomInputNumberComponent.vue";
export default {
  name: "InputCssWidthComponent",
  components: {
    CustomInputNumberComponent,
  },
  props: ["value", "label", "labelCols", "disabled"],
  watch: {
    value: "parseValue",
  },
  data: () => ({
    units: ["auto", "%", "px"],
    index: 0,
  }),
  async mounted() {
    this.parseValue();
    await this.$nextTick();
    this.onInput(this.inputValue);
  },
  computed: {
    inputValue() {
      return this.value
        ? parseInt(("" + this.value).replace(/[^\d]/g, "") || "0")
        : 0;
    },
    unitValue() {
      return this.units[this.index];
    },
    computedDisabled() {
      return this.disabled === true || this.disabled === "true";
    },
    inputDisabled() {
      return this.computedDisabled || this.unitValue === "auto";
    },
  },
  methods: {
    parseValue() {
      if (!this.value) {
        this.index = 0;
      } else if (("" + this.value).endsWith("%")) {
        this.index = 1;
      } else if (("" + this.value).endsWith("px")) {
        this.index = 2;
      } else {
        this.index = 0;
      }
    },
    async changeUnit() {
      this.index = (this.index + 1) % this.units.length;
      await this.$nextTick();
      this.onInput(this.inputValue);
    },
    onInput(value) {
      this.$emit(
        "input",
        this.unitValue === "auto" ? "auto" : "" + value + this.unitValue
      );
    },
    onChange(value) {
      this.$emit(
        "change",
        this.unitValue === "auto" ? "auto" : "" + value + this.unitValue
      );
    },
    focus() {
      this.$refs.input.focus();
    },
    async validate() {
      await this.$refs.input?.validate();
    },
  },
};
</script>
