<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Modifier les colonnes</template>
    <template v-if="liste">
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <b-row
        v-for="(carac, index) in liste.caracs"
        :key="carac.ordre"
        class="mb-3"
      >
        <b-col>
          <CustomInputComponent
            ref="input-libelle"
            label="Libellé"
            :labelCols="3"
            rules="required"
            v-model="carac.libelle"
          ></CustomInputComponent>
          <InputCssWidthComponent
            label="Taille"
            :labelCols="3"
            v-model="carac.largeur"
          ></InputCssWidthComponent>
          <CustomSelectComponent
            ref="select-type"
            label="Type"
            :labelCols="3"
            :options="options"
            rules="required"
            v-model="carac.type"
          ></CustomSelectComponent>
          <CustomSelectComponent
            v-if="carac.type && TypeCarac[carac.type].sources.length > 0"
            ref="select-source"
            label="Source"
            :labelCols="3"
            :options="TypeCarac[carac.type].sources"
            rules="required"
            v-model="carac.champSource"
          ></CustomSelectComponent>
          <CustomSelectComponent
            v-if="carac.type && TypeCarac[carac.type].destinations.length > 0"
            ref="select-destination"
            label="Destination"
            :labelCols="3"
            :options="TypeCarac[carac.type].destinations"
            rules="required"
            v-model="carac.champDestination"
          ></CustomSelectComponent>
          <b-button-toolbar style="justify-content: center">
            <b-button-group>
              <b-button
                class="rounded-left"
                variant="outline-primary"
                :disabled="index <= 0"
                @click="moveUpCarac(index)"
              >
                <b-icon-caret-up-fill></b-icon-caret-up-fill>
              </b-button>
              <b-button
                class="rounded-right"
                variant="outline-primary"
                :disabled="index >= liste.caracs.length - 1"
                @click="moveDownCarac(index)"
              >
                <b-icon-caret-down-fill></b-icon-caret-down-fill>
              </b-button>
            </b-button-group>
            &nbsp;
            <b-button pill variant="danger" @click="removeCarac(index)">
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-toolbar>
        </b-col>
      </b-row>
      <b-button block pill variant="outline-primary" @click="addCarac">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="d-none d-md-inline">&nbsp; Nouvelle colonne</span>
      </b-button>
    </template>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../../ErrorComponent.vue";
import CustomInputComponent from "../../inputs/CustomInputComponent.vue";
import InputCssWidthComponent from "../../inputs/InputCssWidthComponent.vue";
import CustomSelectComponent from "../../inputs/CustomSelectComponent.vue";
import UtilsService from "../../../services/utils.service";
import ApproService, {
  TypeCarac,
  methods,
} from "../../../services/appro.service";
export default {
  name: "ListeProduitsEditionDialog",
  components: {
    ErrorComponent,
    CustomInputComponent,
    InputCssWidthComponent,
    CustomSelectComponent,
  },
  data: () => ({
    error: null,
    liste: null,
    resolvePromise: null,
    rejectPromise: null,
    TypeCarac,
  }),
  computed: {
    options() {
      return Object.values(TypeCarac);
    },
  },
  methods: {
    emptyCaracDefinition: methods.emptyCaracDefinition,
    show(options) {
      this.error = null;
      this.liste = UtilsService.deepCopy(options.liste);
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    addCarac() {
      this.liste.caracs.push({
        ...this.emptyCaracDefinition(),
        ordre: this.liste.caracs.length,
      });
    },
    moveUpCarac(index) {
      UtilsService.moveUp(this.liste.caracs, index);
      this.liste.caracs.forEach((c, i) => (c.ordre = i));
    },
    moveDownCarac(index) {
      UtilsService.moveDown(this.liste.caracs, index);
      this.liste.caracs.forEach((c, i) => (c.ordre = i));
    },
    removeCarac(index) {
      this.liste.caracs.splice(index, 1);
      this.liste.caracs.forEach((c, i) => (c.ordre = i));
    },
    async validate() {
      try {
        let inputs = [
          ...(this.$refs["input-libelle"] ?? []),
          ...(this.$refs["select-type"] ?? []),
          ...(this.$refs["select-source"] ?? []),
          ...(this.$refs["select-destination"] ?? []),
        ];
        for (const input of inputs) {
          await input.validate();
        }
      } catch {
        return;
      }

      this.error = null;
      try {
        await ApproService.updateListeProduits(this.liste);
        this.$refs["modal"].hide();
        this.resolvePromise(true);
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
  },
};
</script>
