<template>
  <b-form-group
    v-if="label"
    :label="label"
    label-cols="auto"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :content-cols="contentCols"
  >
    <b-form-checkbox
      ref="checkboxLabel"
      :switch="computedSwitch"
      :disabled="computedDisabled"
      :value="valueChecked"
      :unchecked-value="valueUnchecked"
      :checked="checked"
      @input="($event) => $emit('input', $event)"
    >
      {{ checked === valueChecked ? labelChecked : labelUnchecked }}
    </b-form-checkbox>
  </b-form-group>
  <b-form-checkbox
    v-else
    ref="checkboxNoLabel"
    :switch="computedSwitch"
    :disabled="computedDisabled"
    :value="valueChecked"
    :unchecked-value="valueUnchecked"
    :checked="checked"
    @input="($event) => $emit('input', $event)"
  >
    {{ checked === valueChecked ? labelChecked : labelUnchecked }}
  </b-form-checkbox>
</template>

<script>
import { computed as inputsComputed } from "../../services/inputs.service";
export default {
  name: "CustomCheckboxComponent",
  props: {
    // valeur de l'input
    checked: undefined,
    // valeur lorsque cochée
    valueChecked: {
      type: undefined,
      default: true,
    },
    // valeur lorsque non cochée
    valueUnchecked: {
      type: undefined,
      default: false,
    },
    // label lorsque cochée
    labelChecked: {
      type: String,
      default: "oui",
    },
    // label lorsque non cochée
    labelUnchecked: {
      type: String,
      default: "non",
    },
    // propriété label du form-group
    label: String,
    // propriété label-cols du form group
    labelCols: {
      type: [String, Number, Object],
      default: "auto",
    },
    // propriété content-cols du form group
    contentCols: {
      type: [String, Number],
      default: "auto",
    },
    // propriété disabled de la checkbox
    disabled: [Boolean, String],
    // propriété switch de la checkbox
    switch: {
      type: [Boolean, String],
      default: true,
    },
  },
  computed: {
    ...inputsComputed,
    computedSwitch() {
      return this.switch === true || this.switch === "true";
    },
  },
  methods: {
    focus() {
      this.$refs.checkboxLabel?.focus();
      this.$refs.checkboxNoLabel?.focus();
    },
  },
};
</script>
