<template>
  <section>
    <TitleBar>
      {{ titre }}
      <template #toolbar-content>
        <b-button-group>
          <b-button pill variant="outline-primary" @click="$router.go(-1)">
            <b-icon-arrow-left></b-icon-arrow-left>
            Retour
          </b-button>
        </b-button-group>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <div v-if="catalogue == null" class="text-center text-warning my-3">
        <b-spinner class="align-middle"></b-spinner>
        <br />
        <strong>Chargement en cours...</strong>
      </div>
      <div v-else-if="listesProduits.length === 0" class="text-center my-3">
        Ce catalogue ne contient encore aucune liste de produits.
        <br />
        Acc&eacute;der &agrave; Strapi pour les cr&eacute;er.
      </div>
      <template v-else>
        <ListeProduitsComponent
          v-for="(liste, index) in listesProduits"
          :key="liste.id"
          :ref="`liste-${liste.id}`"
          :idListe="liste.id"
          :visible="index === 0"
          @show="hideAllExcept(liste.id)"
        ></ListeProduitsComponent>
      </template>
    </b-container>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import ListeProduitsComponent from "../../components/appro/ListeProduitsComponent.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ListesProduitsView",
  components: {
    TitleBar,
    ErrorComponent,
    ListeProduitsComponent,
    ConfirmationDialog,
  },
  data: () => ({
    error: null,
    catalogue: null,
  }),
  computed: {
    titre() {
      return this.catalogue == null
        ? "Chargement en cours..."
        : this.catalogue.attributes.titre;
    },
    listesProduits() {
      return this.catalogue?.attributes.listesProduits?.data ?? [];
    },
  },
  async mounted() {
    this.$store.dispatch("ref/revalidateAppro");
    this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
  methods: {
    async load() {
      // Réinitialiser l'état du composant
      this.error = null;
      this.catalogue = null;

      // Récupérer les infos du catalogue depuis l'API
      try {
        let response = await StrapiService.getCatalogue(this.$route.params.id);
        response.data.data?.attributes.listesProduits?.data?.sort(
          UtilsService.sortByNumberProperty("attributes.ordre")
        );
        this.catalogue = response.data.data;
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    hideAllExcept(idListe) {
      this.listesProduits
        .filter((l) => l.id !== idListe)
        .forEach((l) => this.$refs[`liste-${l.id}`][0].hide());
    },
  },
};
</script>
