<template>
  <section>
    <CustomInputComponent
      ref="input"
      :label="label"
      :labelCols="labelCols"
      :value="message"
      @input="(value) => $emit('inputMessage', value)"
      @change="(value) => $emit('changeMessage', value)"
    >
      <template #append>
        <b-input-group-text @click="changeStatut">
          <b-icon
            :icon="computedStatut.icon"
            :variant="computedStatut.variant"
          ></b-icon>
        </b-input-group-text>
      </template>
    </CustomInputComponent>
  </section>
</template>

<script>
import { StatutCommande } from "../../../services/appro.service";
import CustomInputComponent from "../../inputs/CustomInputComponent.vue";
export default {
  name: "InputStatutCommandeComponent",
  components: { CustomInputComponent },
  props: ["statut", "message", "label", "labelCols"],
  data: () => ({ statuts: Object.values(StatutCommande) }),
  computed: {
    computedStatut() {
      return this.statuts.find((s) => s.name === this.statut) ?? this.status[0];
    },
  },
  methods: {
    changeStatut() {
      let index = (this.computedStatut.ordinal + 1) % this.statuts.length;
      let statut = this.statuts.find((s) => s.ordinal == index).name;
      this.$emit("inputStatut", statut);
      this.$emit("changeStatut", statut);
    },
    focus() {
      this.$refs.input.focus();
    },
    async validate() {
      await this.$refs.input?.validate();
    },
  },
};
</script>
