<template>
  <b-container fluid class="widget-frame p-2 p-md-4">
    <b-row role="button" @click="toggle">
      <b-col class="px-0 px-md-3">
        <h2 class="d-inline-block">{{ titre }}</h2>
        <em v-if="modified">
          <strong class="text-danger">
            modification non enregistr&eacute;es
          </strong>
        </em>
      </b-col>
      <b-col cols="auto">
        <h2>
          <b-icon-chevron-up v-if="innerVisible"></b-icon-chevron-up>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </h2>
      </b-col>
    </b-row>
    <b-row v-if="liste">
      <b-col>
        <div class="px-2 px-md-5">
          <b-collapse
            v-model="innerVisible"
            @show="$emit('show')"
            @shown="$emit('shown')"
            @hide="$emit('hide')"
            @hidden="$emit('hidden')"
          >
            <b-row class="my-3" align-h="between">
              <b-col>
                <b-button-toolbar>
                  <b-button-group>
                    <b-button
                      class="rounded-left"
                      variant="outline-primary"
                      @click="editCaracs"
                    >
                      <b-icon-table></b-icon-table>
                      &nbsp;Modifier les colonnes
                    </b-button>
                    <b-button
                      class="rounded-right"
                      variant="primary"
                      @click="addProduit"
                    >
                      <b-icon-plus-circle></b-icon-plus-circle>
                      &nbsp;Ajouter un produit
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
              <b-col cols="auto">
                <b-button-toolbar>
                  <template v-if="true">
                    &nbsp;
                    <b-button-group>
                      <b-button
                        class="rounded-left"
                        variant="outline-primary"
                        @click="load"
                      >
                        <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
                        &nbsp;Recharger
                      </b-button>
                      <b-button
                        class="rounded-right"
                        variant="primary"
                        @click="validate"
                      >
                        <b-icon-hdd></b-icon-hdd>
                        &nbsp;Enregistrer
                      </b-button>
                    </b-button-group>
                  </template>
                </b-button-toolbar>
              </b-col>
            </b-row>
            <UnpaginatedTable
              :fields="produitFields"
              :items="liste.produits"
              :sortBy="null"
              :sortDesc="false"
            >
              <template #empty>
                <p>Il n'y a aucun produit dans cette liste pour le moment.</p>
                <p>
                  <strong>Astuce :</strong><br />
                  Vous pouvez l'initialiser par copie d'une autre liste pour
                  d&eacute;marrer plus rapidement !
                </p>
              </template>
              <template
                v-for="carac in liste.caracsASaisir"
                #[`cell(carac_${carac.idCarac})`]="{ item }"
              >
                <b-form-textarea
                  :key="carac.idCarac"
                  rows="2"
                  max-rows="6"
                  v-model="item.caracs[carac.idCarac].valeur"
                  @input="modified = true"
                ></b-form-textarea>
              </template>
              <template #cell(statut)="{ item }">
                <InputStatutCommandeComponent
                  label=""
                  :message="item.messageStatut"
                  @inputMessage="
                    (value) => {
                      item.messageStatut = value;
                      modified = true;
                    }
                  "
                  :statut="item.statut"
                  @inputStatut="
                    (value) => {
                      item.statut = value;
                      modified = true;
                    }
                  "
                ></InputStatutCommandeComponent>
              </template>
              <template #cell(actions)="{ index }">
                <b-button-toolbar>
                  <b-button-group>
                    <b-button
                      class="rounded-left"
                      variant="outline-primary"
                      :disabled="index <= 0"
                      @click="moveUpProduit(index)"
                    >
                      <b-icon-caret-up-fill></b-icon-caret-up-fill>
                    </b-button>
                    <b-button
                      class="rounded-right"
                      variant="outline-primary"
                      :disabled="index >= liste.produits.length - 1"
                      @click="moveDownProduit(index)"
                    >
                      <b-icon-caret-down-fill></b-icon-caret-down-fill>
                    </b-button>
                  </b-button-group>
                  &nbsp;
                  <b-button pill variant="danger" @click="removeProduit(index)">
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </b-button-toolbar>
              </template>
              <template #row-details="{ item: prod }">
                <div class="ml-5">
                  <b-table
                    hover
                    :fields="condFields"
                    :items="
                      liste.caracs.filter(
                        (c) => c.type === typesCarac.SAISIE_QUANTITE.value
                      )
                    "
                    :sort-by="null"
                    :sort-desc="false"
                  >
                    <template #cell(carac)="{ item }">
                      {{ item.libelle }}
                    </template>
                    <template #cell(produit)="{ item: carac }">
                      <SelectProduitApproComponent
                        label=""
                        :societe="exploitationCourante.codeSociete"
                        v-model="
                          prod.conditionnements[carac.idCarac].codeProduit
                        "
                        @input="modified = true"
                      ></SelectProduitApproComponent>
                    </template>
                    <template #cell(statut)="{ item: carac }">
                      <CustomCheckboxComponent
                        label=""
                        :checked="
                          prod.conditionnements[carac.idCarac].commandable
                        "
                        @input="
                          (value) => {
                            prod.conditionnements[carac.idCarac].commandable =
                              value;
                            modified = true;
                          }
                        "
                      ></CustomCheckboxComponent>
                    </template>
                  </b-table>
                </div>
              </template>
            </UnpaginatedTable>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
    <ListeProduitsEditionDialog
      ref="listeProduitsEditionDialog"
    ></ListeProduitsEditionDialog>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </b-container>
</template>

<script>
import UnpaginatedTable from "../UnpaginatedTable.vue";
import InputStatutCommandeComponent from "./inputs/InputStatutCommandeComponent.vue";
import SelectProduitApproComponent from "./inputs/SelectProduitApproComponent.vue";
import CustomCheckboxComponent from "../inputs/CustomCheckboxComponent.vue";
import ListeProduitsEditionDialog from "./dialogs/ListeProduitsEditionDialog.vue";
import ConfirmationDialog from "../dialogs/ConfirmationDialog.vue";
import ApproService, { TypeCarac, methods } from "../../services/appro.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ListeProduitsComponent",
  components: {
    UnpaginatedTable,
    InputStatutCommandeComponent,
    SelectProduitApproComponent,
    CustomCheckboxComponent,
    ListeProduitsEditionDialog,
    ConfirmationDialog,
  },
  props: ["idListe", "visible"],
  data: () => ({
    innerVisible: false,
    error: null,
    liste: null,
    modified: false,
    condFields: [
      { key: "carac", label: "Colonne" },
      { key: "produit", label: "Produit" },
      { key: "statut", label: "Commandable" },
    ],
    typesCarac: TypeCarac,
  }),
  computed: {
    titre() {
      return this.liste == null ? "Chargement en cours..." : this.liste.titre;
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    produitFields() {
      let fields = [];
      this.liste?.caracs
        .filter((c) => c.type.startsWith("INFO"))
        .forEach((c) =>
          fields.push({ key: `carac_${c.idCarac}`, label: c.libelle })
        );
      fields.push({ key: "statut", label: "Statut" });
      fields.push({
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px" },
      });
      return fields;
    },
  },
  async created() {
    this.innerVisible = this.visible ?? !this.$store.getters["affichageMobile"];
    await this.load();
  },
  methods: {
    ...methods,
    show() {
      this.innerVisible = true;
    },
    toggle() {
      this.innerVisible = !this.innerVisible;
    },
    hide() {
      this.innerVisible = false;
    },
    async load() {
      this.error = null;
      this.liste = null;
      this.modified = false;

      try {
        let response = await ApproService.getListeProduits(this.idListe);
        let liste = response.data;

        // Identifier les champs à saisir par le salarié
        liste.caracsASaisir = liste.caracs.filter(
          (c) =>
            c.type === TypeCarac.INFO_LIBRE.value ||
            c.type === TypeCarac.INFO_AUTO.value
        );
        this.ajouterValeursCaracsVides(liste);

        // Identifier les champs de commande
        liste.caracsCond = liste.caracs.filter(
          (c) => c.type === TypeCarac.SAISIE_QUANTITE.value
        );
        this.ajouterConditionnementsVides(liste);

        // Afficher la zone des conditionnements
        liste.produits.forEach((p) => {
          p._showDetails = true;
        });
        this.liste = liste;
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    moveUpProduit(index) {
      UtilsService.moveUp(this.liste.produits, index);
      this.liste.produits.forEach((p, i) => (p.ordre = i));
      this.modified = true;
    },
    moveDownProduit(index) {
      UtilsService.moveDown(this.liste.produits, index);
      this.liste.produits.forEach((p, i) => (p.ordre = i));
      this.modified = true;
    },
    removeProduit(index) {
      this.liste.produits.splice(index, 1);
      this.liste.produits.forEach((p, i) => (p.ordre = i));
      this.modified = true;
    },
    async validate() {
      this.error = null;
      try {
        let liste = UtilsService.deepCopy(this.liste);
        await ApproService.updateListeProduits(liste);
        this.load();
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    async editCaracs() {
      let result = await this.$refs.listeProduitsEditionDialog.show({
        liste: this.liste,
      });
      if (result) {
        this.load();
      }
    },
    async addProduit() {
      if (this.modified) {
        let confirmation = await this.$refs.confirmationDialog.show({
          title: "Enregistrer les modifications en cours ?",
          messages: [
            `Ajouter une nouvelle ligne nécessite d'enregistrer les modifications en cours.`,
            `Etes-vous sûr de vouloir continuer ?`,
          ],
        });
        if (!confirmation) {
          return;
        }
      }
      this.liste.produits.push({
        ...this.emptyProduitCatalogue(),
        ordre: this.liste.produits.length,
      });
      await this.validate();
    },
  },
};
</script>
